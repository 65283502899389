
import { useEffect, useState } from "react";
import "../styles/globals.css";
import { SnackbarProvider } from "notistack";
import Head from 'next/head';
function MyApp({ Component, pageProps }) {
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  } else {
    return (
      <>
         <Head>
        {/* Global meta tags (optional, shared across pages) */}

        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3600}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
        {/* <StarsCanvas /> */}
          <Component {...pageProps} />
        </SnackbarProvider>
      </>
    );
  }
}

export default MyApp;
